import React from 'react';
import { Link } from "gatsby"

class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // provide onClick, or link (for internal links), or href (for external links).
    let button = (
      <button className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 border-b-4 border-blue-700 hover:border-blue-800 rounded focus:outline-none" onClick={this.props.onClick}>
        {this.props.children}
      </button>
    );
    if (this.props.href) {
      button = (
        <a href={this.props.href} target='_blank' rel="nofollow noopener noreferrer">
          {button}
        </a>
      )
    } else if (this.props.link) {
      button = (
        <Link to={this.props.link}>
          {button}
        </Link>
      )
    }
    return (
      <div>
        {/* <div>
          <button className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded">
            {this.props.children}
          </button>
        </div> */}
        <div>
          {button}
        </div>
        {/* <button className="mt-4 bg-blue-200 text-sm px-4 py-2 font-medium text-blue-700 hover:text-gray-900 hover:bg-blue-100 rounded-lg transition easy-in-out duration-100">
          {this.props.children}
        </button> */}
      </div>
    );
  }
}

export default Button;