import React from 'react';

class Spoiler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false }
  }

  toggle = () => {
    this.setState({open: !this.state.open});
  }

  render() {
    let { open } = this.state
    return (
      <div className="border border-gray-400 rounded-md px-2 mt-5 mb-5">
        <div className="flex flex-row items-center py-2 font-medium text-opacity-75 hover:text-opacity-100 text-gray-700 cursor-pointer" onClick={this.toggle}>
          {
            open
            ? <svg className="w-6 h-6 text-grey-500 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"/>
              </svg>
            : 
            <svg className="w-6 h-6 text-grey-500 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
            </svg>
          }
          {this.props.title || 'Spoiler'}
        </div>
        {
          open
          ? <div className="pb-4 px-1">
              {this.props.children}
            </div>
          : ''
        }
      </div>
    );
  }
}

export default Spoiler;