import React from 'react';

class CodePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openIndex: 0 }
  }

  setPanel = (idx) => {
    this.setState({openIndex: idx});
  }

  getLanguage(classes) {
    if (classes.toLowerCase().indexOf('java') !== -1)
      return this.formatLanguage('java');
    else if (classes.toLowerCase().indexOf('c++') !== -1)
      return this.formatLanguage('c++');
    else
      return this.formatLanguage('java');
  }

  formatLanguage(lang) {
    if (!lang) 
      return lang
    return lang.charAt(0).toUpperCase() + lang.slice(1)
  }

  render() {
    let { openIndex } = this.state;
    return (
      <div className="mt-6">
        <div className="flex flex-row -mb-2">
          {
            this.props.children.map((child, i) => {
              if (this.state.openIndex == i) {
                return (
                  <div className="select-none inline font-semibold cursor-pointer px-6 pb-2 border-b-2 border-gray-800" onClick={(e) => this.setPanel(i, e)}>
                    {this.getLanguage(child.props.children.props.className)}
                  </div>
                )
              }
              return (
                <div className="select-none inline font-normal px-6 cursor-pointer pb-2 border-b-2 border-gray-300" onClick={(e) => this.setPanel(i, e)}>
                  {this.getLanguage(child.props.children.props.className)}
                </div>
              )
            })
          }
          <div className="flex w-full pb-2 border-b-2 border-gray-300">
          </div>
        </div>
        <div>
          {
            this.props.children.map((child, i) => {
              const visible = (i === openIndex)
              return (
                visible && child
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default CodePanel;