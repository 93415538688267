import React from 'react';
import Button from "./Button.tsx";

import FirebaseManager from '../firebase/firebaseManager.js';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

class ProblemButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: FirebaseManager.dataLoaded,
      solved: this.checkIfSolved()
    };
  }

  toggleSolved = () => {
    FirebaseManager.toggleArticleProblem(this.props.id);
  }

  progressDataUpdated = () => {
    this.setState({
      dataLoaded: FirebaseManager.dataLoaded,
      solved: this.checkIfSolved()
    });
  }

  checkIfSolved() {
    return FirebaseManager.getCompletedArticleProblems().indexOf(this.props.id) > -1 || false;
  }

  componentDidMount() {
    FirebaseManager.subscribe(this.progressDataUpdated);
  }

  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.progressDataUpdated);
  }

  render() {
    return (
      <div id="problem-to-solve">
        <div className="flex space-x-4 items-center">
          <div>
            <Button href={this.props.href}>
              {this.props.children}
            </Button>
          </div>
          { !this.state.dataLoaded &&
            <Tippy content="Please log in to save your progress" animation="scale" duration={[200, 0]}>
              <div className="flex items-center cursor-pointer transition ease-in-out duration-200 transform hover:scale-105">
                <div className="w-5 h-5 border-2 bg-gray-100 border-gray-300 bg-white cursor-pointer rounded">
                  <svg className="invisible w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </div>
                <div className="ml-2 mb-2 border-b text-base h-6 border-gray-400 border-dashed text-gray-500">
                  unsolved
                </div>
              </div>
            </Tippy>
          }
          { this.state.dataLoaded &&
            <div className="flex items-center cursor-pointer transition ease-in-out duration-200 transform hover:scale-105" onClick={this.toggleSolved}>
              { !this.state.solved ?
                <div className="w-5 h-5 border-2 border-gray-400 hover:border-gray-500 bg-white cursor-pointer rounded">
                  <svg className="invisible w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </div> :
                <div className="w-5 h-5 border-2 border-green-500 hover:border-green-500 bg-white cursor-pointer bg-green-500 rounded">
                  <svg className="w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </div>
              }
              {
                this.state.solved ?
                <div className="ml-2 mb-2 border-b text-base h-6 border-green-500 border-dashed text-green-500">
                  solved
                </div> :
                <div className="ml-2 mb-2 border-b text-base h-6 border-gray-500 border-dashed text-gray-500">
                  unsolved
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ProblemButton;