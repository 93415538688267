import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Link } from "gatsby"
import Spoiler from '../components/Spoiler';
import CodePanel from '../components/CodePanel';
import ProblemsList from '../components/ProblemsList';

import ArticlesSidebar from '../components/ArticlesSidebar'

import NextArticleLink from '../components/NextArticleLink';
import TopicsManager from "../topics/topicsManager.js";
import ArticleLikesCounter from "../components/ArticleLikesCounter"
import Button from "../components/Button.tsx";
import ActionItems from "../components/ActionItems.tsx";
import ProblemButton from "../components/ProblemButton.tsx";

import ArticleStatsBar from "../components/ArticleStatsBar.tsx";

import actionItemsList from '../actionItems/actionItems.js';
import articleProblemsList from '../articleProblems/articleProblems.js';

class Article extends React.Component {
  constructor(props) {
    super(props);
    
    let sidebarOpen = true;
    if (typeof localStorage !== `undefined`) {
      sidebarOpen = !(localStorage.getItem('sidebarOpen') === 'false');
    }
    this.state = {sidebarOpen: sidebarOpen};

    this.sidebar = React.createRef();
  }

  componentDidMount() {
    this.restoreSidebarScroll();
  }

  getSidebarScroll() {
    if (this.sidebar && this.sidebar.current)
      return this.sidebar.current.scrollTop;
  }

  restoreSidebarScroll() {
    if (typeof localStorage !== `undefined`) {
      let sidebarScroll = localStorage.getItem('sidebarScroll');
      if (sidebarScroll === null)
        return;
      localStorage.removeItem('sidebarScroll');
      if (this.sidebar && this.sidebar.current)
        this.sidebar.current.scrollTop = sidebarScroll;
    }
  }

  saveSidebarScroll = () => {
    if (typeof localStorage !== `undefined`) {
      if (this.sidebar && this.sidebar.current)
        localStorage.setItem('sidebarScroll', this.getSidebarScroll());
    }
  }

  toggleSidebar = () => {
    if (typeof localStorage !== `undefined`) {
      if (!this.state.sidebarOpen)
        localStorage.setItem('sidebarOpen', 'true');
      else
        localStorage.setItem('sidebarOpen', 'false');
    }
    this.setState({sidebarOpen: !this.state.sidebarOpen});
  }

  render() {
    const post = this.props.data.mdx;
    const shortcodes = {Button, ActionItems, Link, Spoiler, CodePanel, ProblemsList, ProblemButton};

    let formattedSlug = post.fields.slug;
    if (formattedSlug.startsWith("/"))
      formattedSlug = formattedSlug.substr(1);
    if (formattedSlug.endsWith("/"))
      formattedSlug = formattedSlug.slice(0, -1);

    const actionItems = actionItemsList
      .find((articleItems) => articleItems.article === formattedSlug)?.actionItems || [];

    const articleProblems = articleProblemsList
      .find((articleProblems) => articleProblems.article === formattedSlug)?.problems || [];

    return (
      <Layout selectedArticle={formattedSlug}>
        <SEO title={post.frontmatter.title} />
        <div className="flex flex-row">
          { !this.state.sidebarOpen &&
            <div className="w-16 mt-2 fixed inset-0 pl-2 top-0 h-screen hidden md:block bg-white z-10 pt-24">
              <div onClick={this.toggleSidebar} className="bg-white cursor-pointer -mt-6">
                <svg className="rounded-full p-2 hover:bg-gray-100 h-10 w-10 text-gray-400 hover:text-gray-600 transition duration-100 ease-in-out" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </div>
            </div>
          }
          { this.state.sidebarOpen &&
            <div className="w-1/4 mt-2 fixed inset-0 pl-4 top-0 h-screen hidden md:block bg-white z-10 overflow-auto pt-24"
                 ref={this.sidebar}> 
              <ArticlesSidebar onSelect={this.saveSidebarScroll} onToggle={this.toggleSidebar} selectedArticle={formattedSlug} />
            </div>
          }
          <div className={(this.state.sidebarOpen ? 'md:w-3/4 ' : '') + "h-screen fixed top-0 right-0 overflow-auto w-full pt-20 md:pt-24 mt-6 pb-24"}>
            <div className="mx-auto px-4 md:px-2 prose">
              <h1>{post.frontmatter.title}</h1>
            </div>
            <div className="mx-auto flex px-4 md:px-2 mb-6 mt-4" style={{maxWidth: '65ch'}}>
              <ArticleStatsBar 
                tableOfContents={post.tableOfContents}
                actionItems={actionItems}
                articleProblems={articleProblems}
              />
            </div>
            <div className="mx-auto px-4 md:px-2 prose">
              <MDXProvider components={shortcodes}>
                <MDXRenderer>
                  {post.body}
                </MDXRenderer>
              </MDXProvider>
            </div>
            <div className="mx-auto mt-16 px-2 flex items-center " style={{maxWidth: '65ch'}}>
              {/* <ArticleLikesCounter articleName={formattedSlug} /> */}
              <div className="ml-auto">
                <NextArticleLink nextArticle={TopicsManager.getNextArticle(formattedSlug)} />
              </div>
            </div>
            <img className="mx-auto transition ease-in-out duration-300 transform hover:scale-105 w-3/5 md:w-1/2 lg:w-1/2 max-w-xs mt-16 md:mt-16 mb-5 md:mb-2" src={'/' + post.frontmatter.featuredImage} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Article;

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        featuredImage
      }
      fields {
        slug
      }
      tableOfContents
    }
  }
`