import React from 'react';

class ProblemsList extends React.Component {
  render() {
    return (
      <div>
        <ul>
          {
            this.props.problems.map((problem) => (
              <li key={problem.name}>
                <a href={problem.link} target='_blank'>{problem.name}</a>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

export default ProblemsList;