import React from 'react';

import FirebaseManager from '../firebase/firebaseManager.js';

class ArticleStatsBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tocOpen: false, isSignedIn: FirebaseManager.isSignedIn }
  }

  toggleToc = () => {
    this.setState({tocOpen: !this.state.tocOpen});
  }

  userDataUpdated = () => {
    this.setState({
      isSignedIn: FirebaseManager.isSignedIn
    });
  }

  componentDidMount() {
    FirebaseManager.subscribe(this.userDataUpdated);
  }

  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.userDataUpdated);
  }

  render() {
    let { tocOpen } = this.state;
    let actionItems = this.props.actionItems;
    let articleProblems = this.props.articleProblems;

    const completedActionItems = actionItems.filter((item) => FirebaseManager.getCompletedActionItems().indexOf(item.id) > -1).length;
    const completedArticleProblems = articleProblems.filter((problem) => FirebaseManager.getCompletedArticleProblems().indexOf(problem) > -1).length;

    return (
      <div>
        <div className="flex">
          { this.props.tableOfContents.items && 
            <div className="w-40 mr-4">
              <a onClick={this.toggleToc} className="cursor-pointer text-sm border-b border-gray-400 border-dashed text-gray-500">
                { !tocOpen ? 'Show Table of Contents' : 'Hide Table of Contents' }
              </a>
            </div>
          }
          { actionItems.length > 0 &&
            <div>
              <a className="cursor-pointer text-sm border-b border-gray-400 border-dashed text-gray-500" href="#action-items">
                {
                  this.state.isSignedIn ? 
                  <>{completedActionItems} of {actionItems.length}&nbsp;{'action items completed'}</> :
                  <>{actionItems.length}&nbsp;{actionItems.length === 1 ? 'action item' : 'action items'}</>
                }
              </a>
            </div>
          }
          { articleProblems.length > 0 &&
            <div>
              <a className="cursor-pointer text-sm border-b border-gray-400 border-dashed text-gray-500" href="#problem-to-solve">
                {
                  this.state.isSignedIn ? 
                  <>{completedArticleProblems} of {articleProblems.length}&nbsp;{'problems solved'}</> :
                  <>{articleProblems.length}&nbsp;{articleProblems.length === 1 ? 'problem to solve' : 'problems to solve'}</>
                }
              </a>
            </div>
          }
        </div>
        {
          tocOpen && this.props.tableOfContents.items && this.props.tableOfContents.items.map((item) => {
            return (
              <div>
                {
                  item.title && <a className="text-sm text-gray-600" href={item.url}>{item.title}</a>
                }
                {
                  item.items && item.items.map((subItem) => {
                    return (
                      <div className={item.title && "pl-3"}>
                        <a className="text-sm text-gray-600" href={subItem.url}>{subItem.title}</a>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    );
  }
}

export default ArticleStatsBar;