import React from 'react';
import { Link } from "gatsby"

class NextArticleLink extends React.Component {
  render() {
    const nextArticle = this.props.nextArticle;
    return (
      <div className="">
        { nextArticle ? 
          <Link to={'/' + nextArticle.name} className="flex font-medium no-underline text-right text-gray-700 hover:text-gray-900 hover:bg-blue-100 rounded-lg p-2 transition easy-in-out duration-100">
            Next Article: {nextArticle.title}
            <svg className="ml-2 w-6 h-6"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"/>
            </svg>
          </Link> : ''
        }
      </div>
    );
  }
}

export default NextArticleLink;
