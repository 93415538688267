import React from 'react';

// import remark from 'remark';
// import recommended from 'remark-preset-lint-recommended';
// import remarkHtml from 'remark-html';

import actionItemsList from '../actionItems/actionItems.js';
import FirebaseManager from '../firebase/firebaseManager.js';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

class ActionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: FirebaseManager.dataLoaded,
      completed: this.checkIfCompleted()
    };
  }
  checkIfCompleted() {
    return FirebaseManager.getCompletedActionItems().indexOf(this.props.data.id) > -1;
  }
  progressDataUpdated = () => {
    this.setState({
      dataLoaded: FirebaseManager.dataLoaded,
      completed: this.checkIfCompleted()
    });
  }
  componentDidMount() {
    FirebaseManager.subscribe(this.progressDataUpdated);
  }
  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.progressDataUpdated);
  }
  check = () => {
    FirebaseManager.toggleActionItem(this.props.data.id);
  }
  render() {
    const disabled = !this.state.dataLoaded;
    return (
      <div className="py-4">
        <Tippy content="Please log in to save your progress" animation="scale" duration={[200, 0]} disabled={!disabled} placement="auto" arrow={true}>
          <div className={(!disabled ? "cursor-pointer " : "cursor-pointer ") + "flex items-start mb-2 hover:bg-gray-100 rounded-md py-1 px-1"} onClick={!disabled ? this.check : () => {}}>
            { !disabled ?
              (
                !this.state.completed ?
                <div className="mt-1 w-5 h-5 border-2 border-blue-400 mr-3 hover:border-blue-500 bg-white cursor-pointer rounded">
                  <svg className="invisible w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </div> :
                <div className="mt-1 w-5 h-5 border-2 border-blue-500 mr-3 hover:border-blue-500 cursor-pointer bg-blue-500 rounded">
                  <svg className="w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                  </svg>
                </div>
              ) : 
              <div className="mt-1 w-5 h-5 border-2 bg-gray-100 border-gray-300 mr-3 bg-white rounded">
                <svg className="invisible w-5 h-5 pr-1 pb-1 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                </svg>
              </div>
            }
            <div className="font-medium">
              <span className="inline text-gray-700">{this.props.idx + 1}.</span>&nbsp;{this.props.data.title}
            </div>
          </div>
        </Tippy>
        <div className="px-1">
          {this.props.data.description}
        </div>
      </div>
    )
  }
}

class ActionItems extends React.Component {
  // parseMarkdownFromString(s) {
  //   return remark()
  //     .use(remarkHtml)
  //     .processSync(s)
  //     .toString();
  // }
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: FirebaseManager.dataLoaded,
      completedActionItems: FirebaseManager.getCompletedActionItems(),
    };
  }

  progressDataUpdated = () => {
    this.setState({
      dataLoaded: FirebaseManager.dataLoaded,
      completedActionItems: FirebaseManager.getCompletedActionItems()
    });
  }

  componentDidMount() {
    FirebaseManager.subscribe(this.progressDataUpdated);
  }

  componentWillUnmount() {
    FirebaseManager.unsubscribe(this.progressDataUpdated);
  }

  render() {
    const actionItems = actionItemsList
      .find((arcticleItems) => arcticleItems.article === this.props.article)?.actionItems || [];

    const completedActionItems = actionItems.filter((item) => FirebaseManager.getCompletedActionItems().indexOf(item.id) > -1);

    return (
      <>
        { actionItems.length > 0 && <div className="mt-8 mb-10">
          <span id="action-items" className="-mt-16 pb-16 block"></span>
          <div className="rounded-md shadow">
            <div className="px-4 py-2 rounded-md rounded-b-none bg-gray-100 border-gray-200 border">
              <div className="text-gray-700 font-semibold">
                Action Items
              </div>
              <div className="text-sm text-gray-500">
                {completedActionItems.length} / {actionItems.length} completed
                {
                  !FirebaseManager.isSignedIn && <span className="font-medium"> (Log in to complete action items)</span>
                }
              </div>
            </div>
            <div className="border-b border-l border-r border-gray-200 px-3 pb-2 divide-y divide-gray-400 divide-opacity-50">
              {
                  actionItems.map((item, idx) => (
                      <ActionItem 
                        key={idx}
                        data={item}
                        idx={idx}
                      />
                    )
                  )
              }
            </div>
          </div>
        </div>
        }
      </>
    )
  }
}

export default ActionItems;